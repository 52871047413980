module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a3a2dd9416aed6867ee0c15e18711c9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"cookieName":"focuscookie-analytics","trackingId":"G-JF2BGJFP87"},"googleTagManager":{"cookieName":"focuscookie-marketing","trackingId":"AW-11485386843","defaultDataLayer":{"platform":"gatsby"}},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"**/*.html":["Cache-Control:public, max-age=0, must-revalidate"],"/public/page-data/*":["Cache-Control:public, max-age=0, must-revalidate"],"/public/page-data/app-data.json":["Cache-Control:public, max-age=0, must-revalidate"],"/static/*":["Cache-Control: public, max-age=31536000, immutable"],"**/*.js":["Cache-Control: public,max-age=31536000, immutable"],"**/*.css":["Cache-Control: public,max-age=31536000, immutable"],"**/*.woff2":["Cache-Control: public,max-age=31536000, immutable"],"**/*.woff":["Cache-Control: public,max-age=31536000, immutable"],"/static/fonts/*":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"fonts/*.woff2":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"],"/static/fonts/*.woff2":["Cache-Control: public,max-age=31536000,s-maxage=31536000,immutable"]},"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-11485386843"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
